import styled, {css} from 'styled-components';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';
import { DropdownButton } from "react-bootstrap";


const Flexbox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  > div {
    .highcharts-container {
      width: 100% !important;
      > svg {
        width: 100%;
        .xAxis {
          > text {
            font-size: 11px !important;
            @media (max-width: 1000px) {
              font-size: 12px !important;
            }
          }
        }
      }  
    } 
  }
`;

const GroupButton = styled.div`
  background-color: ${({bgColor}) => bgColor ? bgColor: ''};
  text-transform: ${({capitalize}) => capitalize ? 'capitalize' : 'uppercase' };
  cursor: pointer;
  color: ${({textColor}) => textColor ? textColor : 'white'};
  white-space: ${({noSpace}) => noSpace ? 'normal' : 'nowrap'} ;
  padding: ${({padding,newPadding}) => padding ? padding : newPadding ? '0px 0px 0px 0px'  :'4px 7px'}
  width: ${({width}) => width ? width: '100%'};
  height: 100%;
  font-size: ${({fontSize}) => fontSize ? fontSize : '9px'}
  line-height: 1.428571429;
  border-radius:  ${({radius}) => radius ? radius : '30px'};
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 300};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0'};;
  float: ${({float}) => float ? 'left': 'right'};
  margin-top: ${({float, marginTop}) => float ? '15px':  marginTop ?  marginTop : '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '0px'};
  text-align: ${({Align}) => Align ? 'center': 'left'};
  opacity: ${({bgOpacity}) => bgOpacity ? bgOpacity: 1};
  font-family: ${({fontFamily}) => fontFamily ? fontFamily :'Rubik-Medium'};
  display: flex;
  align-items: center;
  justify-content: ${({justifycontent}) => justifycontent ? 'flex-start' :'center'};
  @media(max-width: 500px) {
    font-size: ${({smallFont}) => smallFont && smallFont };
  }
`;

const GridGroup = styled.div`
  width: 48px;
  margin-right: 6px;
  display: inline-block;
  @media(max-width: 1366px){
    width: 42px;
  }
`;

const ActivityFlex = styled.div`
  display: block;
  overflow-y: hidden;
  white-space: nowrap;
  overflow-x: auto;
  background-color: ${({bgColor}) => bgColor && bgColor };
  align-items: center;
  justify-content: center;
  height: 30px;
  overflow-y; hidden:
`;

const SplitFitnessSection = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '35%'};
  height: ${({height}) => height ? height: 'auto'};
  padding-right: ${({PaddingRight}) => PaddingRight ? '25px': '0px'};
  margin-left: ${({marginLeft}) => marginLeft ? '25px': '0px'};
  float: left;
  margin-bottom: ${({mbotton}) => mbotton ? mbotton: '25px'};
  display: ${({displayNone}) => displayNone && 'none'};
  @media (max-width: 1260px) {
    padding-right: 15px;
  }
  @media (max-width: 1200px) {
      width: ${({ShowActiveUserSection}) => ShowActiveUserSection ? '100%' : '100%'};
      float: left;
  }
  @media (max-width: 1080px) {
    width: ${({ smallWidth }) => smallWidth ? smallWidth : '100%'};
  } 
  @media (max-width: 760px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 15px;
    padding:${({ smallPadding }) => smallPadding && smallPadding };
  }
  .boxShadow {
    float: left;
    width: 100%;
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 6px;
    height: ${({height}) => height ? '100%' : 'auto'};
    .card-top {
      float: left;
      width: 100%;
      height: 85px;
      background-color: #0D4270;
      border-radius: 6px 6px 0px 0px;
    }
    
    .groupActivity {
      padding-left: 15px;
      text-transform: capitalize;
      height: 52px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: ${BoldFont};
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #FFFFFF;
      float: left;
    }
    .emp_count {
    margin-bottom: -64px;
    padding-left: 18px;
    display: flex;
    padding-top: -1px;
      width: 100%;
      font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
   text-align: center;
     color: #69C2FF;
    } 
  .button_cls {
    height: 84px;
    padding-right: 15px;
    height: -10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
    .statistic-bar {
      background-color: #1E76AB;
      min-height: 30px;
      float: left;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      > p {
      font-family: ${MediumFont};
      font-size: 10px;
      line-height: 22px;
      color: #FFFFFF;
      margin: 0;
    }
    .radio-button-container {
      display: flex;
      background: #fff;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      border-radius: 4px;
      padding: 0 5px;
      width: 60%
      display: none
    }

  } 
`;

const FitnessCommonContainer = styled.div`
  width:  ${({newWidth}) => newWidth ? newWidth : '100%'};
  height: ${({fitnessHeight,newHeight, height}) => height ? `calc(100% - 60px)` : fitnessHeight ? '290px' : newHeight ? 'none' : '100%'};
  padding: ${({padding,newPadding}) => padding ? padding : newPadding ? '6px' :'10px'};
  border-top: ${({ borderBottom }) => borderBottom ? '2px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: ${({float}) => float ? 'capitalize': 'uppercase'}; 
  font-family: ${({float}) => float ? 'Rubik-Medium': ''};
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
  &:first-child{
    border-top: ${({ borderBottom }) => borderBottom ? '2px solid #3a3a3a' : ''};
  }
  & > div:last-child {
  border-radius:  0 0 6px 6px;
  }
  
  .fitnessDeviceHeight {
    padding-top: 10px;
  }
  .highcharts-credits {
    display: none; 
  }

  .border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
     padding-left: 45px;
     border:unset !important;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }  
  .SubSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
     padding-left: 45px;
     height: 45px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }
  .SubSectionNew {
    display: flex;
    align-items: center;
    justify-content: space-between;
     padding-left: 55px;
     height: 45px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  
  ${({ flex }) => flex && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    overflow: auto;
  `}
`;
const FitnessChampionsCard = styled.div`
  display: inline-block;
  width: ${({newWidth}) => newWidth ? "calc(100%/2)" : 'calc(100%/3)'};
  float: left;
  padding: ${({newPadding}) => newPadding ? "0px 0px" : '10px 5px'};
  @media (max-width: 1200px) {
    width: calc(100%/3);
  }
  @media(max-width: 760px) {
    width: calc(100%/2);
    float: left;
  } 
  @media(max-width: 500px) {
    width: 100%;
  }
   
  ${({ isBigImage }) => isBigImage && css`
    img {
      height: 85px !important;
      width: 85px !important;
    }
  `}
`;

const FitnessChampionsContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  text-align: center;
  width: 100%;
   font-size: 12px;
  position: relative;
  height: 100%;
  
  .champion-tag {
   height: 74px;
   background: #0D4270;
   border-radius: 6px 6px 0px 0px;    
  border-radius: 6px 6px 0px 0px;    
   border-radius: 6px 6px 0px 0px;    
  border-radius: 6px 6px 0px 0px;    
   border-radius: 6px 6px 0px 0px;    
    > p {
    font-family: Rubik;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    border-radius: 6px 6px 0px 0px;
    letter-spacing: 1px;
    position: relative;
    bottom: 5px;
    }
  }

  .wellness-pts{
    background:#F3F6F9;
    margin-top:14px;
    text-transform:capitalize;
    .title{
      color: #0D4270;
      text-align: center;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 0px; /* 0% */

    }
    .pts{
      color: #0D4270;
font-family: "Rubik-Medium";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 0px;

    }
  }
  
  .state-city {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9C9C9C; 
    text-transform: capitalize;
  }  
  > div{
    width: 100%;
    height: 100%;
    > div {
       padding: 10px;
      .image {
         border-radius: 6px;
         margin-top: -47px
;
        > img {
          height: 80px;
          border: 3px solid #FFFFFF;
          width: 80px;
          border-radius: 6px;
          object-fit: cover;
          background-color: white;
        }
      }
      .company {
        font-weight: normal;
        text-transform: capitalize;
        color: rgb(153,153,153);
        font-size: 12px;
      }
    }
    .button {
      margin-top: 20px;
      display: none;
      > span{
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }
  .name {
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #3A3A3A;
    text-transform: capitalize;
  }
  
  .designation {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9C9C9C; 
    text-transform: capitalize;
    position: relative;
    bottom: 10px;
  }
  
  .color-band {
    font-family: ${BoldFont};
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: #A5ABBA;
    text-transform: none;
    height: 44px;

  }
`;

const UserInterest = styled.div`
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: wrap;
    padding: 0 3px !important;
    margin: 0 auto;
    min-height: 44px;
    position: relative;
    bottom: 10px;
`;

const ImageContainer = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    background: linear-gradient(360deg, #1E6481 0%, #0D425C 60.94%, #022C43 100%);
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 0px 3px;
    margin-top: 10px;
;
div{
  > img {
      width: 25px !important;
      height: 25px !important;
     }

}
     > img {
      width: 25px !important;
      height: 25px !important;
     }
`;

const TitleContainer = styled.div`
    float: left;
    width: 100%;
    height: ${({height}) => height ? height : 'auto'};
    padding: ${({padding}) => padding ? padding : '5px 0 5px 5px'};
    background-color: ${({bgColor}) => bgColor ? bgColor : 'none'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : 'none'};
    margin: ${({margin}) => margin ? '0 0 30px' : '0'};
    & > img {
      float: left;
    }
    & > div {
      float: right;
      display: flex;
      align-items: center; 
      > a {
        display: block;
        font-size: 14px;
        color: rgb(51,51,51); 
        font-family: ${BoldFont};
       
      } 
      > img {
          width: 8px;
          margin-left: 10px;
      } 
      > i {
          font-size: 25px;
          color: #fff;
          line-height: 0;
      }  
    }
`;

const Title = styled.p`
   letter-spacing: 0.8px;
   font-family: Rubik-Medium;
   font-style: normal;
   font-size: 18px;
   padding-left: 10px;
   font-weight: ${({fontWeight}) => fontWeight ? fontWeight : '500'};
   text-transform: ${({textTransform}) => textTransform ? 'capitalize' : 'none'};
   text-align: left;
   float: left;
   margin: 0;
   color: ${({textColor}) => textColor ? textColor : 'none'};
   max-width: ${({maxWidth}) => maxWidth ? maxWidth : 'none'};
   position: ${({position}) => position ? position : 'none'};
   padding-top: ${({ paddingTop}) => paddingTop ? paddingTop : '0px'};
   line-height: ${({lineHeight}) => lineHeight ? lineHeight : '44px'};
   
   @media (max-width: 1200px) and (min-width: 1024px) {
    max-width: none;
   }
   
   @media (max-width: 1023px) and (min-width: 700px) {
    max-width: 600px;
   }
`;

const StyledButton = styled.button `
   color: ${({btnTextColor}) => btnTextColor ? btnTextColor : '#1C4667'};
   float: right;
   background: none;
   border: none;
   font-size: 14px;
   outline: none;
   & > img {
     width: 8px;
     margin-left: 10px;   
   }
`;

const FitnessEmployeeCard = styled.div`
  margin: 0px;
  background-color: white;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: ${({paddingTop}) => paddingTop ? '0px 10px 10px 10px': '10px'};

  .emp-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .top-name {
    font-size: 14px;
    margin-left: 5px;
  }
`;

const FitnessNewUserCard = styled(FitnessEmployeeCard)`
  padding: ${({padding}) => padding ? padding : '0px' };
  border-bottom: ${({borderNone}) => borderNone ? 'none' : '2px solid #E0E0E7' };
  
  > svg{
    width: ${({width}) => width ? width: '5%'};
  }

  height: auto;
  .employee-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  ${({ height }) => height && css`
    height: ${({height}) => height ? height : '66px' }; 
    @media (max-width: 1050px) and (min-width: 951px) {
      height: auto;
      min-height: 66px;
    }
  `}
`;

const FitnessEmployeeGrid = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '20%'};
  position: ${({ position}) =>  position ?  position : 'none'};
  right: ${({right}) =>  right ?  right : '0px'};
  border-radius: ${({borderRadius}) => borderRadius ? borderRadius : 'none'};
  font-size: ${({textAlign,newPadding}) => textAlign ? '30px': newPadding ? '14px' : '12px' };
  font-family: ${({newPadding}) => newPadding ? 'Rubik-Medium': 'none'};
  text-align: ${({textAlign}) => textAlign ? 'right': 'left'};
  color: ${({color}) => color ? color : 'rgb(62,78,104)'};
  padding-top: ${({paddingTop, padding}) => padding ? padding : paddingTop ? '6px': '0px'};
  padding-left: ${({ paddingLeft,newPadding }) => paddingLeft ? '5px':newPadding ? '10px' : '0px' }
  text-align: ${({ groupActivity }) => groupActivity && 'center'};
  display: ${({ groupActivity }) => groupActivity && 'flex'}; 
  justify-content: ${({ groupActivity }) => groupActivity && 'center'};
  align-items: ${({ groupActivity }) => groupActivity && 'center'};
  font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };
  background-color: ${({bgColor}) => bgColor ? bgColor : ''};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '0px'};
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  ${({ employeeGrid }) => employeeGrid && css`
    font-weight: 300;
  `}

  @media (max-width: 1200px) {
    font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
  }

  > img {
    border-radius: ${({borderRadius}) =>  borderRadius ? '' : '50%'};
    width: ${({minWidth, initaitive}) =>  minWidth ?  initaitive ? '23px' : '30px' :'50px'};
    height: ${({minWidth, initaitive}) =>  minWidth ? initaitive ? '23px' : '30px' :'50px'};
    width: ${({imgWidth}) => imgWidth ? '50px' : 'none'}
    height: ${({imgWidth}) => imgWidth ? 'auto' : 'none'}
  }
  .company {
    font-weight: normal;
    text-transform: uppercase;
    color: rgb(153,153,153);
    }
  }
  .units{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0D4270;
  }
  > button {
    background-color: #26b0c1;
    color: white;
  }
  > svg {
    height: 25px;
    width: ${({width}) => width ? '40px': '25px'};
    @media(max-width: 500px) {
      width: ${({width}) => width ? '30px': '20px'};
    }
  }
  .fitnessDevice {
    font-family: Rubik-Medium;
   font-style: normal;
    font-weight: 500;
    font-size: 16px;
   line-height: 30px;
   text-align: center;
   color: #0D4270;
    border-radius: 6px;
    text-align: center;
    padding: 5px;
  }
  .deviceName {
    font-family: Rubik-Medium;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  position: relative;

   color: #3A3A3A;
    > span {
      display: flex;
      text-transform: capitalize; !important
      display: block;
      font-family: Rubik;
     font-style: normal;
    font-weight: normal;
     font-size: 14px;
     line-height: 24px;
      color: #9C9C9C;
    }
  }
  .dayInterval {
    text-transform: capitalize;
    font-size: 14px;
    font-family: ${BoldFont};
    font-weight: 500;
    color: #282C37;

  }
  .name {
    font-family: ${BoldFont};
    font-size: 17px;
    padding-left: 5px;
    color: #333333;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .user-name {
    font-family: ${BoldFont};
    font-size: 16px;
    color: #333333;
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .values {
    font-size: 14px;
    font-family: ${BoldFont};
    padding-left: 24px;
    @media(max-width: 500px) {
      font-size: 11px;
    }
  }
  
  .step {
    font-size: 14px;
    font-family: ${BoldFont};
    @media(max-width: 500px) {
      font-size: 11px;
    }
  }
  
  .device-images {
    width: 30px;
    height: auto;
    margin-right: 10px;
  }
  
  ${({ isBigImage }) => isBigImage && css`
    .device-images {
      width: 42px;
      margin-right: 10px;
    }
  `}
`;

const FitnessTabContainer = styled.div`
  height: auto;
  margin-top: -20px;
  float: left;
  width: 100%;
  @media (max-width: 1260px) {
    padding: 42px 20px 30px 20px;
  }
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
  .addbutton{
    cursor: pointer;
    display: inline-block;
    float: right;
    text-align: center;
    background-color: #159fc9;
    color: white;
    font-size: 14px;
    font-family: ${BoldFont};
    width: 20px;
    border-radius: 50%;
  }
`;
const FitnessContainer = styled.div`
  height: auto;
  display: flex;
  width: 100%;
  margin-bottom: ${({marginBottom}) => marginBottom ? '15px': '0px'}; 
  @media (max-width: 1200px) {
    display: block;
  }
  @media (max-width: 760px) { 
    display: block;
    width: 100%;
    float: left;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: ${({fitnessHeight}) => fitnessHeight ? '286px' : '100%'};
  padding: ${({padding}) => padding ? padding : '10px'};
  border-bottom: ${({ borderBottom }) => borderBottom ? '2px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  background: white;
  padding: 0px 0px 15px 15px;
  
  & > div > div:nth-child(2) {
    padding: 0 10px
  }
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
`;

const GroupActivityValue = styled.div`
   width: 100%;
   float: left;
   font-family: ${MediumFont};
`;

const ProfileLogoContainer = styled.img`
  width: 17px;
  height: 17px;
  margin-right: 10px;
`;

const MinutesActiveDescription = styled.div`
 float: left;
 padding: 15px 20px;
 border-bottom: 1px solid #ededed;
 background-color: rgb(243,246,249);
 & > p {
  font-size: 15px;
  font-family: ${MediumFont};
  color: #282C37;
  margin: 0;
 }
`;

const FitnessUserCardContainer = styled.div`
   float: left;
   width: 100%;
   padding: 0 20px;
`;

const CurrentYear = styled.div`
   float: right;
   padding-right: 5px;
   & > p {
    font-size: 14px;
    margin: 0;
    color: #fff;
    margin-right: 7px;
   } 
`;

const NewCardContainer = styled.div`
  height: 125px;
  width: calc((100% - 50px) / 3);
  margin-top: 15px;
  display: inline-block;
  margin-right: 25px;

  background: #fff;
  padding: 0 10px;
  border-radius: 4px; 
  &:nth-child(3n+0) {
    margin-right: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 1270px) {
    padding: 0 5px;
  }
  
  @media (max-width: 1225px) and (min-width: 501px) {
    width: calc((100% - 18px)/2);
    margin: 10px 0px 10px 0;
    padding: 0 10px;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
  }
  
`;
const NewBoxShadow = styled.div`
  float: left;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
`;

const Row1 = styled.div`
  float: left;
  width: 100%
  padding: ${({padding}) => padding ? '10px 0 0': '0'};
  display: ${({display}) => display ? 'flex': ''};
  .textdata{
    width: calc(100% - 52px);
    font-size: 16px;
    float: left;
    margin: 0;
    text-transform: capitalize;
    color: #0D4270;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    margin: auto;
    padding-left: 17px;
    padding-right: 35px;
    >p{
      margin: 0px;
    }
  }
  
  & > p {

      font-size: 12px;
    float: left;
    margin: 0px;
    text-transform: uppercase;
    color: rgb(111, 118, 126);
    padding: 13px 0px 0px 7px;
    line-height: 17px;
    font-family: Rubik-Medium;
    font-weight: 700;
       @media (max-width: 1290px) and (min-width: 1225px) {
       max-width: 120px;   
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 50px;
    height: 50px;
    float: right;
  }
  .totaldata {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    font-size: 16px;
    font-family: 'Rubik-Medium';
    color: #3a3a3a;
    >span{
      font-size: 16px;
      font-family: 'Rubik';
      color: #3a3a3a;
      padding-left: 5px;
    }
  }
`;

const ImgContainer = styled.div`
    float: left;
    width: ${({width}) => width ? width : '52px' };
    height: ${({height}) => height ? height : '52px' };
    background-color: ${({bgColor }) => bgColor ? bgColor : '#fff'};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
       width: 36px;
       height: 36px;
      
    }
    >div {
      width: 36px;
      height: 36px;
    > svg{
      width: 36px;
      height: 36px;
      > g {
        fill: ${({color}) => color ? color : '#000'};
      }
      > g path {
        fill: ${({color}) => color ? color : '#000'};
      }
      mask{
        rect{
          fill: ${({color}) => color ? color : '#000'};
        }
      }
      > path {
        fill: ${({color}) => color ? color : '#000'};
      }
    }
  }
`;

const NewImgContainer = styled.div`
   width: 100%
   height: 8px;
   border-radius: 3px
   background-color: ${({color }) => color ? color : '#fff'};
    
    & > img {
       width: 60%;
       height: 60%;
      
    }
`;

const BarLine = styled.div`
 height: 8px;
 border-radius: 3px
 width:${({newWidth}) =>  newWidth ? newWidth : '0%'};
 background-color:${({color}) =>  color ? color : '#3a3a3a'};
 margin-top: 8px
`;

const BoxData = styled.div`
  margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
  background-color: ${({bgColor}) => bgColor ? bgColor : '#1E76AB'};
  font-family: Rubik-Medium;
 font-style: normal;
  font-weight: 500;
  font-size: 16px;
 line-height: 30px;
 text-align: center;
 color: #0D4270;
  border-radius: 6px;
  text-align: center;
  padding: 5px;
`;

const NewTotalData = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-family: 'Rubik-Medium';
  margin-top: 10px;
  color:${({color}) =>  color ? color : '#3a3a3a'};
  >span{
    font-size: 16px;
    font-family: 'Rubik';
    color: #3a3a3a;
    padding-left: 5px;
  }
`;

const NewFitnessCommonContainer = styled.div`
  width:  ${({newWidth,fitnessHeight}) => newWidth ? newWidth : fitnessHeight ? 'none' : '100%'};
  height: ${({fitnessHeight}) => fitnessHeight ? '100%' : '100%'};
  border-bottom: ${({ borderBottom }) => borderBottom ? '2px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  font-size: 12px;
  padding-left: 6px;
  text-transform: ${({float}) => float ? 'capitalize': 'uppercase'}; 
  font-family: ${({float}) => float ? 'Rubik-Medium': ''};
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };
  }
  @media(min-width: 1400px) {
    height: 100%;
  }
  & > div:last-child {
  border-radius:  0 0 6px 6px;
  }
  
  .fitnessDeviceHeight {
    padding-top: 10px;
  }
  .highcharts-credits {
    display: none; 
  }

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
     padding-left: 24px;
     padding-top: 2px;
    .button {
      display: flex;
      > span{
        text-transform: uppercase;
        padding: 3px;
        > div {
          display: inline-block;
        }
      }
      .text {
        margin-left: 10px;
      }
    }
  }

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
  
  ${({ flex }) => flex && css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    overflow: auto;
  `}
`;

const CustomDropDown = styled(DropdownButton)`
  width:120px;
  display: ${({inputdisplay}) => inputdisplay ? inputdisplay : 'inline-block'};
  color: ${({newWidth}) => newWidth ? 'white' : 'white'};
  background-color: ${({newWidth}) => newWidth ? '#0D4270' : '#ffffff'};
  align-items: center;
  padding: 0px 10px;
  border: 1px solid #cdcbcb;
  text-align: left;
  border: none;
  margin-right:${({marginRight})=>marginRight ? marginRight:'0'}
  margin-left:${({marginLeft})=>marginLeft ? marginLeft:'0'}

  padding: ${({inputpadding,newWidth}) => inputpadding ? inputpadding : newWidth ? '9px 14px' : '9px 29px'};
  border-radius: 3px;
  color: #9c9c9c;
  background-color: rgba(105, 194, 255, 0.1);
  &:active, &:hover, &:focus, &:active:focus {
    color: #0D4270;
  }

  button{
    background:transparent !important;
    border:none;
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
    font-size:18px;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Rubik';
    float: left;
    max-width:118px;
    white-space:nowrap;
    font-size: 14px;
    line-height: 24px;
    color: #9c9c9c;
  }
  > ul {
    max-width: 300px !important;
  }
  font-size: 18px;
  line-height:21px;
  letter-spacing: 0px;
  color: #282C37;
  font-family: ${MediumFont};
  color: #9c9c9c;
  .caret {
    margin-left: 5px;
    float: right;
    margin-top: 9px;
    display: flex;
    align-self: center;

    color: #69C2FF;

  }
  > li > a {
    text-overflow: ellipses;
    white-space: normal;
    overflow: hidden;
  }
  > span {
    color: #a3a3a3;
    float: right;
    border-top: 6px dashed;
    margin-top: 7px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
     background-color: rgba(105, 194, 255, 0.1);
    color: #0D4270;
  }
`;

export {NewFitnessCommonContainer,BarLine,Row1,NewTotalData,BoxData,ImgContainer,NewImgContainer,NewBoxShadow,NewCardContainer, ActivityFlex,Flexbox,GridGroup, GroupButton, SplitFitnessSection, FitnessCommonContainer,  FitnessChampionsContainer, ImageContainer, FitnessChampionsCard, UserInterest, TitleContainer, Title, StyledButton, FitnessEmployeeCard, FitnessNewUserCard, FitnessEmployeeGrid, FitnessTabContainer, FitnessContainer, CardContainer, GroupActivityValue, ProfileLogoContainer, MinutesActiveDescription, FitnessUserCardContainer, CurrentYear, /*WellnessGoalPieChart, ColorBox*/CustomDropDown}
